// Firebase
import { auth, firestore } from "../firebase/Firebase";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import { addDoc, collection, doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { UserRoles } from '../firebase/UserIntefaces'
//Joi
import Joi from '@hapi/joi';

export type BookedPackage ='candidate' | 'employee' | 'employee_and_candidate';
interface CreateCompanyData {
    company: {
        city: string;
        name: string;
        participators: number;
        street: string,
        zipCode: string,
        bookedPackage: BookedPackage,
    },
    contact: {
        firstName: string,
        lastName: string,
        telephone: string,
    },
    empoloyeeAccess: boolean,
    registerDate: number,
    email: string,
    password: string,
    passwordConfirmation: string,
    language: 'de' | 'en' | 'fr'
}

const schema = Joi.object().keys({
    company: Joi.object().keys({
        city: Joi.string().required(),
        name: Joi.string().required(),
        participators: Joi.number().integer().required().min(1),
        street: Joi.string().required(),
        zipCode: Joi.string().required().min(5).max(5),
        bookedPackage: Joi.string().required().valid('candidate', 'employee', 'employee_and_candidate'),
    }),
    contact: Joi.object().keys({
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        telephone: Joi.string().required(),
    }),
    empoloyeeAccess: Joi.boolean(),
    registerDate: Joi.number().integer().required(),
    email: Joi.string().required().email(),
    password: Joi.string().required().regex(/((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).(?!.*[\s]).{8,100})/),
    passwordConfirmation: Joi.any().required().equal(Joi.ref('password')),
    language: Joi.string().valid(['de', 'en', 'fr']).required()
})

export const useCreateCompany = () => {

  

  const createCompany = async (data: CreateCompanyData) => {

    const result = Joi.validate(data, schema, { abortEarly: false });
    if (result.error) return { error: result.error };

    let { company, empoloyeeAccess, registerDate, contact, email, language, password } = data;

    let role = UserRoles.NOTHING

    if(company.bookedPackage === 'candidate') {
      role = UserRoles.RECRUITING
      // Override employee access
      empoloyeeAccess = true;
    } else if(company.bookedPackage === 'employee') {
      role = UserRoles.COMPANY
    } else if(company.bookedPackage === 'employee_and_candidate') {
      role = UserRoles.COMPANY_AND_RECRUITING
      // Override employee access
      //empoloyeeAccess = true;
    }

    const resultUser = await createUserWithEmailAndPassword(auth, email, password)

    const testGroupRef = await addDoc(collection(firestore, 'testGroups'),{
      label: company.name + ' initial',
      active: false,
      limit: company.participators
    })
    const companyRef = await addDoc(collection(firestore, 'companies'),{
      company,
      contact,
      subscription: "DEFAULT",
      active: false,
      language,
      empoloyeeAccess: empoloyeeAccess,
      registerDate: new Timestamp(Math.floor(registerDate / 1000), 0),
      testGroups: [testGroupRef],
      uid: resultUser.user.uid
    }) 
    await updateDoc(testGroupRef, {
      company: companyRef
    })
    await setDoc(doc(firestore,'users',resultUser.user.uid), {
      role: role,
      company: companyRef
    })
    return { success: true }
}

return createCompany;
}
