//React
//import React from 'react'
import React, { useState, useEffect } from 'react'

//UseTranslation
import { useTranslation } from "react-i18next"
//FirebaseContext
import { useUserContext } from '../../../firebase/FirebaseContext'

import LoadingScreenNew from "../../layout/LoadingScreenNew"
import { IUserData } from '../../../hooks/useUpdateProfile'
import { transformStrenghAndRisksToDataObject, ICompetenceData } from '../../../helpers/transform'
import { UseGetMatchingEmployee } from '../../../hooks/useGetMatchingEmployee'
import IndividualResultsBar from "../../layout/IndividualResultsBar"
import EmployeeDevelopment from '../../layout/EmployeeDevelopment'
import { ChevronLeftIcon } from '@heroicons/react/solid'

const EmployeeResults: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const { i18n } = useTranslation(['employeeResults', 'basics'])
  const { employeeUser, resultLocalesStandard, resultLocalesStrengthsAndRisks } = useUserContext()

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getAllMatchingEmployee = UseGetMatchingEmployee()
        let matchEmployee = await getAllMatchingEmployee(employeeUser?.uid, employeeUser?.testGroups)
        if(matchEmployee) {
          setUserCompetenceData(matchEmployee.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[employeeUser])


  //const strengthAndRiskLanguage = i18n.language.includes('de') ? 'strengthsAndRisksDE' : 'strengthsAndRisksEN'
  var strengthAndRiskLanguage = 'strengthsAndRisksDE'
  if(i18n.language.includes('en')) {
    strengthAndRiskLanguage = 'strengthsAndRisksEN'
  } else if(i18n.language.includes('fr')) {
    strengthAndRiskLanguage = 'strengthsAndRisksFR'
  }

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformStrenghAndRisksToDataObject(resultLocalesStrengthsAndRisks[strengthAndRiskLanguage])
  }

    return competenceData ? (
      <>
          <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"> 
            <div className="mt-8 mb-8">
            <IndividualResultsBar active={[false, true]} employeeView={true}></IndividualResultsBar>
            </div>
            <EmployeeDevelopment competenceData={competenceData.category[2]} userData={userCompetenceData} points={userCompetenceData['g8t_category2']} />
            <EmployeeDevelopment competenceData={competenceData.category[5]} userData={userCompetenceData} points={userCompetenceData['g8t_category5']} />
            <EmployeeDevelopment competenceData={competenceData.category[7]} userData={userCompetenceData} points={userCompetenceData['g8t_category7']} />
            <EmployeeDevelopment competenceData={competenceData.category[3]} userData={userCompetenceData} points={userCompetenceData['g8t_category3']} />
            <EmployeeDevelopment competenceData={competenceData.category[4]} userData={userCompetenceData} points={userCompetenceData['g8t_category4']} />
            <EmployeeDevelopment competenceData={competenceData.category[8]} userData={userCompetenceData} points={userCompetenceData['g8t_category8']} />
            <EmployeeDevelopment competenceData={competenceData.category[6]} userData={userCompetenceData} points={userCompetenceData['g8t_category6']} />
            <EmployeeDevelopment competenceData={competenceData.category[1]} userData={userCompetenceData} points={userCompetenceData['g8t_category1']} />

            <div className="pt-8 pb-8"> 
              <a href='/employee'>
                <button
                  type="button"
                  className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                </button>
              </a>
            </div>
          </div>
        </main>
      </>
    ) : (<><LoadingScreenNew /></>)
  
}
export default EmployeeResults;
