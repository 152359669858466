//Firebase
import { createUserWithEmailAndPassword } from "@firebase/auth"
import { auth, firestore } from "../firebase/Firebase"
import { addDoc, collection, doc, DocumentReference, setDoc, Timestamp } from "firebase/firestore"
import { EStatus } from "./useGetJobStats"
//Joi
import Joi from '@hapi/joi'

interface IJobStatus {
    date: Timestamp,
    status: EStatus
}
export interface IJob {
    [index: string]: {
        jobRef: DocumentReference,
        date: Timestamp,
        jobId: string,
        status: IJobStatus[]
    }
}


interface CreateEmployeeData {
    firstName: string,
    lastName: string,
    gender: 'male' | 'female' | 'other' | 'noinformation' | null,
    termsAccepted: number,
    testGroup: string,
    email: string,
    password: string,
    department: string | null,
    jobTitle: string,
    passwordConfirmation: string,
    //privacyAccepted: boolean,
    language: 'de' | 'en' | 'fr',
    role?: string,
    //jobs?: string
}

const schema = Joi.object().keys({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    gender: Joi.string().required().valid('male', 'female', 'other', 'noinformation'),
    department: Joi.string().required(),
    jobTitle: Joi.string().required(),
    termsAccepted: Joi.number().integer().required(),
    testGroup: Joi.string().required(),
    email: Joi.string().required().email(),
    password: Joi.string().required().regex(/((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).(?!.*[\s]).{8,100})/),
    passwordConfirmation: Joi.any().required().equal(Joi.ref('password')),
    language: Joi.string().valid(['de', 'en', 'fr']).required(),
    role: Joi.string(),
    //jobs: Joi.strict(),
})

export const useCreateEmployee = () => {

    const createEmployee = async (data: CreateEmployeeData, jobId: string | null = null) => {
        const result = Joi.validate(data, schema, {abortEarly: false});

        if (result.error) return {error: result.error};

        const { firstName, language, lastName, gender, department, jobTitle, termsAccepted, testGroup, email, password, role } = data;
        console.log(data)

        const resultUser = await createUserWithEmailAndPassword(auth,email,password)

        let employeeObject = {}

        
        if(jobId !== null) {
            
            let job: IJob = {}
            job[jobId] = {
                jobRef: doc(firestore, 'jobs', jobId),
                jobId: jobId,
                date: new Timestamp(Math.floor(termsAccepted / 1000), 0),
                status: [{
                    date: new Timestamp(Math.floor(termsAccepted / 1000), 0),
                    status: EStatus.NEW
                }]
            }
        
            employeeObject = {
                firstName,
                lastName,
                gender,
                email,
                department,
                jobTitle,
                language,
                termsAccepted: new Timestamp(Math.floor(termsAccepted / 1000), 0),
                testGroups: [doc(firestore, 'testGroups', testGroup)],
                uid: resultUser.user.uid,
                jobs: job,
                role: role
            }

        } else {
            employeeObject = {
                firstName,
                lastName,
                gender,
                email,
                department,
                jobTitle,
                language,
                termsAccepted: new Timestamp(Math.floor(termsAccepted / 1000), 0),
                testGroups: [doc(firestore, 'testGroups', testGroup)],
                uid: resultUser.user.uid,
                role: role
            }
        }
        
        const employee = await addDoc(collection(firestore, 'employees'), employeeObject)
        if(!role || role === 'EMPLOYEE') {
            await setDoc(doc(firestore, 'users', resultUser.user.uid), {
                role: 'EMPLOYEE',
                employee
            })
        } else if (role === 'CANDIDATE') {
            await setDoc(doc(firestore, 'users', resultUser.user.uid), {
                role: 'CANDIDATE',
                employee
            })
        }
        
        return {success: true}
    }
    return createEmployee;
}