//React
  import React, { useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import MatchingComparison from "../layout/MatchingComparison"
import MatchingComparisonViewPDF from './MatchingComparisonViewPDF'
import { ChevronLeftIcon, DocumentDownloadIcon } from '@heroicons/react/solid'
import { useParams } from "react-router"
import { IUserData } from '../../hooks/useUpdateProfile'
import { UseGetEmployeeProfile } from '../../hooks/useGetEmployeeProfile'
import { UseGetMatchingEmployee } from '../../hooks/useGetMatchingEmployee'
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformToDataObject, ICompetenceData } from '../../helpers/transform'
import LoadingScreenNew from "../layout/LoadingScreenNew"
import IndividualResultsBar from "../layout/IndividualResultsBar"
import SiteNotFoundScreen from "../../screens/SiteNotFoundScreenDS"

//useTranslation
import { useTranslation } from "react-i18next"

import ReactDOMServer from 'react-dom/server';
// @ts-ignore
import html2pdf from 'html2pdf.js'


const CompanyIndividualComparisonView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const { t, i18n } = useTranslation(['companyMatching', 'departments', 'employeeResults']);
  interface ParamTypes {
    uid: string,
    profileid: string
  }

  const { uid } = useParams<ParamTypes>()

  let profileid


  const {companyUser} = useUserContext()

  let testGroups = companyUser!.testGroups

  const [profileCompetenceData, setProfileCompetenceData] = useState<IUserData>({})
  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [userName, setUserName] = useState('')
  const [userAdditionalInfos, setUserAdditionalInfos] = useState('')
  const [userMail, setUserMail] = useState<string>('')
  const [jobTitle, setJobTitle] = useState<string>('')
  const [department, setDepartment] = useState<string>('')
  
  const { resultLocalesStandard } = useUserContext()
  //const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'
  var standardLanguage = 'standardDE';
  if(i18n.language.includes('en')) {
    standardLanguage = 'standardEN'
  } else if(i18n.language.includes('fr')) {
    standardLanguage = 'standardFR'
  }

  //const { toPDF, targetRef } = usePDF({filename: 'competences_'+userName+'.pdf', options: { scale: 0.5 }});
  //const { toPDF, targetRef } = usePDF({filename: 'competences_'+userName+'.pdf'});
  //const targetRef = useRef();

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
  }

  //console.log(resultLocalesStandard)

  const getProfile = UseGetEmployeeProfile()

  window.scrollTo({top: 0, behavior: 'smooth'});

  useEffect(() => {
    const requestProfile = async () => {
      try {
       
        const getAllMatchingEmployee = UseGetMatchingEmployee()
        let profile = await getProfile(uid)
        let matchEmployee = await getAllMatchingEmployee(uid, testGroups)
        if(matchEmployee) {
          if(profile)
            setProfileCompetenceData(profile?.data)
          setUserCompetenceData(matchEmployee.data)
          setUserName(matchEmployee.firstName+' '+matchEmployee.lastName)
          setUserMail(String(matchEmployee.email))
          if(matchEmployee.department && matchEmployee.jobTitle) {
            setJobTitle(matchEmployee.jobTitle)
            setDepartment(t('departments:'+String(matchEmployee.department)))
            setUserAdditionalInfos(matchEmployee?.jobTitle +' | '+ t('departments:'+String(matchEmployee.department)))
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileid, uid, testGroups, t])

  const downloadPDF = () => {
    const personalData = {
      name: userName,
      email: userMail,
      department: department,
      profile: jobTitle 
    };
  
    const printElement = ReactDOMServer.renderToString(
      <MatchingComparisonViewPDF
        personalData={personalData}
        competenceData={competenceData}
        userData={userCompetenceData}
        profileData={profileCompetenceData}
      />
    );
  
    const options = {
      margin: 20,
      filename: `Result_${userName}.pdf`,
      image: { type: 'jpeg', quality: 0.9 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    html2pdf()
      .from(printElement)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
  
        // Seitenzahlen einfügen
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);

          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(8);
          pdf.setTextColor(0, 128, 128);

          pdf.text(`${i} | ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
        }
      })
      .save();
  };






  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY)

  const breadCrumbHome = {
  
    name: 'home',
    href: '/company/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: userName + ', ' + userAdditionalInfos,
      href: '/company/employee/comparison/' + uid,
      current: true
    }
  ]

  if(companyUser && companyUser!.empoloyeeAccess !== true) {
    return (
      <>
        <SiteNotFoundScreen />
      </>
    )
  }

  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
      <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-5 gap-4 items-center">
            <div className="sm:col-span-3">
              <div className="pt-2 pb-4">
                <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
              </div>
              <div className='flex items-center'>
                <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">
                  {t('companyEmployeeResults:headline.EmployeeResultsHeadline')}
                </h1>
              </div>
            </div>
            <div className="sm:col-span-2 flex sm:justify-end sm:items-center flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <button
                onClick={downloadPDF}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <DocumentDownloadIcon className="-ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                PDF
              </button>
              <a
                href={'/company/requirementprofiles/profile/' + uid}
                className="items-center px-4 py-2 border border-transparent shadow-sm text-sm text-white border border-uw-teal font-medium rounded-md text-bg-teal bg-uw-teal hover:bg-gray-600 focus:outline-none"
              >
                {t('employeeResults:basics.buttonRequirementProfile')}
              </a>
            </div>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="mt-8 mb-8">
              <IndividualResultsBar active={[true, false]}></IndividualResultsBar>
            </div>

            
            <div className="pb-2 mb-4 pt-2">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[25]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[2]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[35]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[19]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[6]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[38]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[29]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[27]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[20]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[9]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[3]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[37]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[31]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[26]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[21]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[17]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[13]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[15]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[4]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[36]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[5]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[30]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[23]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[34]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[10]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[33]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[28]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[24]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[14]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[12]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[7]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[39]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[8]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[1]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[32]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[22]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[18]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[16]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[11]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pt-8"> 
              <a href='/company/dashboard'>
                <button
                  type="button"
                  className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                </button>
              </a>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default CompanyIndividualComparisonView