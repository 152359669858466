// React 
import React, {  SetStateAction, RefObject, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from "react-i18next"
// Components
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import H2 from "../components/textStyling/H2"
import BodyHeader from "../components/companyRegistration/BodyHeader"
import BodyForm from "../components/companyRegistration/BodyForm"
import CompanyRegistrationFaqs from '../components/companyRegistration/RegistrationFaqs';
import { DotLoader } from "react-spinners"
// Hooks
import { useFormFieldState } from '../hooks/useFormFieldState'
import { BookedPackage, useCreateCompany } from '../hooks/useCreateCompany'
import { useCheckWindowWidth } from "../hooks/useCheckWindowWidth"
// Helpers
import { validatePassword } from '../helpers/validatePassword'
import { validateMail } from "../helpers/validateEmail"

import { useUserContext } from "../firebase/FirebaseContext"


const CompanyRegistrationScreen: React.FC = () => {

  const history = useHistory();

  

  const { i18n, t } = useTranslation(['companyRegistration', 'basics'])

  const packages = [
    { value: "candidate", label: i18n.language.includes('de') ? "Recruiting" : 'Recruiting' },
    { value: "employee", label: i18n.language.includes('de') ? "Company" : 'Company' },
    { value: "employee_and_candidate", label: i18n.language.includes('de') ? "Recruiting & Company" : 'Recruiting & Company' }
  ]

  const [alreadyRegistered, setAlreadyRegistered] = useState(false)
  const [companyName, setCompanyName] = useFormFieldState('')
  const [companyNameError, setCompanyNameError] = useState(false)
  const [companyStreet, setCompanyStreet] = useFormFieldState('')
  const [companyStreetError, setCompanyStreetError] = useState(false)
  const [companyCity, setCompanyCity] = useFormFieldState('')
  const [companyCityError, setCompanyCityError] = useState(false)
  const [companyZip, setCompanyZip] = useFormFieldState('')
  const [companyZipError, setCompanyZipError] = useState(false)
  const [companyParticipators, setCompanyParticipators] = useFormFieldState('')
  const [companyParticipatorsError, setCompanyParticipatorsError] = useState(false)
  const [contactFirstName, setContactFirstName] = useFormFieldState('')
  const [contactFirstNameError, setContactFirstNameError] = useState(false)
  const [contactLastName, setContactLastName] = useFormFieldState('')
  const [contactLastNameError, setContactLastNameError] = useState(false)
  const [contactEmail, setContactEmail] = useFormFieldState('')
  const [contactEmailError, setContactEmailError] = useState(false)
  const [contactTelephone, setContactTelephone] = useFormFieldState('')
  const [contactTelephoneError, setContactTelephoneError] = useState(false)
  const [password, setPassword] = useFormFieldState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordConfirmation, setPasswordConfirmation] = useFormFieldState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(false)
  const [employeeAccess, setemployeeAccess] = useState(false);
  const [formSubmittedToggle, setFormSubmittedToggle] = useState(false)
  const [awaitingResults, setAwaitingResults] = useState(false)
  const [bookedPackageError, setBookedPackageError] = useState(false)
  const [bookedPackage, setBookedPackage] = useState<{ value: string; label: string }>()
  

  const createCompany = useCreateCompany();
  const emailRef = React.createRef() as RefObject<HTMLInputElement>
  const windowState = useCheckWindowWidth()

  const { setIsLoggedIn } = useUserContext()

  const termsHandler = () => {
    if (employeeAccess) {
      //setemployeeAccessError(true)
      setemployeeAccess(!employeeAccess)
    } else if (!employeeAccess) {
      //setemployeeAccessError(false)
      setemployeeAccess(!employeeAccess)
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setAwaitingResults(true)

    try {
      setAwaitingResults(true)
      var companyLanguage:'de'|'en'|'fr' = 'de'
      if(i18n.language.includes('en')) {
        companyLanguage = 'en'
      } else if(i18n.language.includes('fr')) {
        companyLanguage = 'fr'
      }
      const create = await createCompany({
        company: {
          name: companyName,
          street: companyStreet,
          city: companyCity,
          zipCode: companyZip,
          participators: Number(companyParticipators),
          bookedPackage: bookedPackage ? bookedPackage.value as BookedPackage : undefined,
        },
        contact: {
          firstName: contactFirstName,
          lastName: contactLastName,
          telephone: contactTelephone,
        },
        email: contactEmail,
        language: companyLanguage,
        password,
        passwordConfirmation,
        registerDate: Date.now(),
        empoloyeeAccess: employeeAccess
      })
      if (create && create.error) {
        setAwaitingResults(false)
        setCompanyNameError(true)
        setCompanyStreetError(true)
        setCompanyZipError(true)
        setCompanyCityError(true)
        setCompanyParticipatorsError(true)
        setContactFirstNameError(true)
        setContactLastNameError(true)
        setContactTelephoneError(true)
        setContactEmailError(true)
        setPasswordError(true)
        setPasswordConfirmationError(true)
        setBookedPackageError(true)

        if (companyName !== "") setCompanyNameError(false)
        if (companyStreet !== "") setCompanyStreetError(false)
        if (companyZip !== "" && companyZip.length === 5) setCompanyZipError(false)
        if (companyCity !== "") setCompanyCityError(false)
        if (companyParticipators !== "") setCompanyParticipatorsError(false)
        if (contactFirstName !== "") setContactFirstNameError(false)
        if (contactLastName !== "") setContactLastNameError(false)
        if (contactTelephone !== "" && contactTelephone.length > 8) setContactTelephoneError(false)
        if (contactEmail !== "" && validateMail(contactEmail)) setContactEmailError(false)
        if (validatePassword(password)) setPasswordError(false)
        if (passwordConfirmation !== '' && password === passwordConfirmation) setPasswordConfirmationError(false)
        if (bookedPackage !== undefined) setBookedPackageError(false)

        setFormSubmittedToggle(!formSubmittedToggle)
        return
      }
      if (create && create.success) {
        setAwaitingResults(true)
        setAlreadyRegistered(false)
        setIsLoggedIn(true)
        //await logIn(contactEmail, password)
        history.push('/company/success')
      }
    } catch (e: any) {
      if (e.code === "auth/email-already-in-use") setAlreadyRegistered(true)
      //console.log(e)
      setAwaitingResults(false)
      setCompanyNameError(true)
      setCompanyStreetError(true)
      setCompanyZipError(true)
      setCompanyCityError(true)
      setCompanyParticipatorsError(true)
      setContactFirstNameError(true)
      setContactLastNameError(true)
      setContactTelephoneError(true)
      setContactEmailError(true)
      setPasswordError(true)
      setPasswordConfirmationError(true)
      setBookedPackageError(true)

      if (companyName !== "") setCompanyNameError(false)
      if (companyStreet !== "") setCompanyStreetError(false)
      if (companyZip !== "" && companyZip.length === 5) setCompanyZipError(false)
      if (companyCity !== "") setCompanyCityError(false)
      if (companyParticipators !== "") setCompanyParticipatorsError(false)
      if (contactFirstName !== "") setContactFirstNameError(false)
      if (contactLastName !== "") setContactLastNameError(false)
      if (contactTelephone !== "" && contactTelephone.length > 8) setContactTelephoneError(false)
      if (contactEmail !== "" && validateMail(contactEmail)) setContactEmailError(false)
      if (validatePassword(password)) setPasswordError(false)
      if (passwordConfirmation !== '' && password === passwordConfirmation) setPasswordConfirmationError(false)
      if (bookedPackage !== undefined) setBookedPackageError(false)
    }
  }

  useEffect(() => {
    if (emailRef.current !== null && !awaitingResults) {
      emailRef.current!.scrollIntoView(false)
    }
  }, [awaitingResults, emailRef])

  const bodyContentFirst = (
    <>
      <div className="flex justify-center mb-10 lg:mb-20 xl:mb-20 mt-12 lg:mt-16 xl:mt-32">
        <div className="lg:w-2/5 xl:w-2/5 lg:text-center xl:text-center xl:mb-16">
          <H1 color="uw-teal">{t('texts.headline')}</H1>
        </div>
      </div>
      <BodyHeader state={windowState} />
    </>)

  const bodyContentGreenBackground = (
    <div className="relative z-10">
      <div
        className="mb-10 lg:mb-16 xl:mb-16 lg:mt-12 xl:mt-12 relative z-10">
        <H2 color="white">{t('form.registration')}</H2>
      </div>
      <BodyForm
        alreadyRegistered={alreadyRegistered}
        autoFocus={[companyNameError,
          !companyNameError && companyStreetError,
          !companyNameError && !bookedPackageError && !companyStreetError && companyZipError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && companyCityError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && companyParticipatorsError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && contactFirstNameError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && !contactFirstNameError && contactLastNameError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && !contactFirstNameError && !contactLastNameError && contactTelephoneError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && !contactFirstNameError && !contactLastNameError && !contactTelephoneError && contactEmailError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && !contactFirstNameError && !contactLastNameError && !contactTelephoneError && !contactEmailError && passwordError,
          !companyNameError && !bookedPackageError && !companyStreetError && !companyZipError && !companyCityError && !companyParticipatorsError && !contactFirstNameError && !contactLastNameError && !contactTelephoneError && !contactEmailError && !passwordError && passwordConfirmationError,
          //!companyNameError && !companyStreetError && !companyZipError && !companyCityError && !bookedPackageError && !companyParticipatorsError && !contactFirstNameError && !contactLastNameError && !contactTelephoneError && !contactEmailError && !passwordError && !passwordConfirmationError && employeeAccessError,
        ]}
        dropDownHandler={setBookedPackage as (bookedPackage: SetStateAction<{ value: string; label: string }>) => void}
        dropDownOptions={packages}
        dropDownValue={bookedPackage!}
        emailRef={emailRef}
        error={[companyNameError, companyStreetError, companyZipError, companyCityError,
          companyParticipatorsError, contactFirstNameError, contactLastNameError, contactTelephoneError,
          contactEmailError, passwordError, passwordConfirmationError, bookedPackageError
        ]}
        formSubmittedToggle={formSubmittedToggle}
        handleSubmit={handleSubmit}
        onChangeHandler={[setCompanyName, setCompanyStreet, setCompanyZip, setCompanyCity,
          setCompanyParticipators, setContactFirstName, setContactLastName, setContactTelephone, setContactEmail,
          setPassword, setPasswordConfirmation
        ]}
        state={windowState}
        termsHandler={termsHandler}
        value={[companyName, companyStreet, companyZip, companyCity, companyParticipators,
          contactFirstName, contactLastName, contactTelephone, contactEmail, password, passwordConfirmation,
          employeeAccess
        ]} />
    </div>
  )

  const bodyContentSecond = (
    <>
        {/*Desktop*/}
        <CompanyRegistrationFaqs />
    </>
  )

  return (
          awaitingResults ?(
          <>
            <Container background="white" headerFooter login>
              <div className="h-screen flex justify-center">
                <div className="flex flex-col align-middle justify-center text-center">
                  <div className="lg:hidden xl:hidden">
                    <H2 color="uw-teal">
                      {t('basics:validation.validationOne')}
                      <br />
                      {t('basics:validation.validationTwo')}
                    </H2>
                  </div>
                  <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
                    <H2 color="uw-teal">
                      {t('basics:validation.validationOne')}
                      <br />
                      {t('basics:validation.validationTwo')}
                    </H2>
                  </div>
                  <div className="mt-12 flex justify-center">
                    <DotLoader color="#008080" loading size={150} />
                  </div>
                </div>
              </div>
            </Container>
          </>) :
    <Container
      bodyContentFirst={bodyContentFirst}
      bodyContentGreenBackground={bodyContentGreenBackground}
      background={"white"}
      bodyContentSecond={bodyContentSecond}
      greenBackground
      headerFooter
      login>
    </Container>
  )
}

export default CompanyRegistrationScreen
