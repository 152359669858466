import React from 'react';
import CustomMenu from '../layout/Menu/CustomMenu';
import Footer from '../layout/FooterNew';
import { INavigation, INavigationMainItem, setMainMenuIdActive } from '../layout/Menu/CustomMenu';
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation';
import { useTranslation } from 'react-i18next';

import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

import { useUserContext } from '../../firebase/FirebaseContext';

import { useToggleCompanyPrivacy } from '../../hooks/useToggleCompanyPrivacy';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const CompanySettingsView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {
    const { i18n } = useTranslation('companySettings');
    const [language, setLanguage] = useState<string>(i18n.language);
    const { companyUser } = useUserContext();

    setMainMenuIdActive(mainNavItems);

    const changeLanguage = (lang: string) => {
        setLanguage(lang);
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const [employeeAccessEnabled, setEmployeeAccessEnabled] = useState(companyUser!.empoloyeeAccess);
    const { t } = useTranslation(['companySettings']);

    let breadCrumbHome = {
        name: 'home',
        href: '/company/dashboard',
        current: false,
    };

    const breadCrumbPaths: INavigationMainItem[] = [
        {
            name: t('headlineBreadcrumb'),
            href: '/company/settings',
            current: true,
        },
    ];

    const toggleCompanyPrivacy = useToggleCompanyPrivacy();
    const togglePrivacy = async (value: boolean) => {
        try {
            await toggleCompanyPrivacy(value);
        } catch (err) {
            return;
        }

        companyUser!.empoloyeeAccess = value;
        setEmployeeAccessEnabled(value);
    };

    return (
        <>
            <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
            <div className="py-10">
                <header className="mt-16">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="pt-2 pb-4">
                            <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths} />
                        </div>
                        <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-10">
                            {t('headline')}
                        </h1>
                    </div>
                </header>
                <main>
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="mt-10 divide-y divide-gray-200">
                            <div className="space-y-1">
                                <h3 className="text-lg leading-6 font-medium text-uw-teal">
                                    {t('accountSettings.headlineAccount')}
                                </h3>
                            </div>
                            <div className="mt-6">
                                <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-sm font-medium text-gray-500">
                                            {t('companySettings:accountSettings.subHeadlineLanguage')}
                                        </dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <div className="flex space-x-4">
                                                <button
                                                    type="button"
                                                    onClick={() => changeLanguage('de')}
                                                    className={classNames(
                                                        language === 'de'
                                                            ? 'text-uw-teal font-bold'
                                                            : 'text-gray-500 hover:text-uw-teal'
                                                    )}
                                                >
                                                    Deutsch
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => changeLanguage('en')}
                                                    className={classNames(
                                                        language === 'en'
                                                            ? 'text-uw-teal font-bold'
                                                            : 'text-gray-500 hover:text-uw-teal'
                                                    )}
                                                >
                                                    English
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => changeLanguage('fr')}
                                                    className={classNames(
                                                        language === 'fr'
                                                            ? 'text-uw-teal font-bold'
                                                            : 'text-gray-500 hover:text-uw-teal'
                                                    )}
                                                >
                                                    Français
                                                </button>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        {companyUser?.role !== 'RECRUITING_DEMO' && companyUser?.role !== 'RECRUITING' ? (
                            <div className="mt-10 divide-y divide-gray-200">
                                <div className="space-y-1">
                                    <h3 className="text-lg leading-6 font-medium text-uw-teal">
                                        {t('privacySettings.headlinePrivacy')}
                                    </h3>
                                </div>
                                <div className="mt-6">
                                    <Switch.Group
                                        as="div"
                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
                                    >
                                        <Switch.Label
                                            as="dt"
                                            className="text-sm font-medium text-gray-500"
                                            passive
                                        >
                                            {t('companySettings:privacySettings.subHeadlineEmployeeAccess')}
                                        </Switch.Label>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <span className="flex-grow">
                                                {t('companySettings:privacySettings.textEmployeeAccess')}
                                            </span>
                                            <span className="lg:ml-24 ml-8 flex-shrink-0">
                                                <Switch
                                                    checked={employeeAccessEnabled}
                                                    onChange={togglePrivacy}
                                                    className={classNames(
                                                        employeeAccessEnabled
                                                            ? 'bg-green-500'
                                                            : 'bg-gray-200',
                                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 sm:ml-auto'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            employeeAccessEnabled
                                                                ? 'translate-x-5'
                                                                : 'translate-x-0',
                                                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                        )}
                                                    />
                                                </Switch>
                                            </span>
                                        </dd>
                                    </Switch.Group>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </main>
            </div>
            <Footer />
        </>
    );
};

export default CompanySettingsView;
