//React
import React, { SetStateAction, useState } from 'react'
import { useHistory } from 'react-router'
import { match } from 'react-router'
import { DotLoader } from "react-spinners"
import { useTranslation } from "react-i18next"
//Components
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import Text from "../components/textStyling/Text"
import H2 from "../components/textStyling/H2"
import H3 from "../components/textStyling/H3";
import Dropdown from "../components/layout/Dropdown"
import InputField from "../components/layout/InputField"
import Button from "../components/layout/Button"
//import  { Redirect } from 'react-router-dom'
//import SwitchButton from "../components/layout/SwitchButton"
//Hooks
import { useFormFieldState } from '../hooks/useFormFieldState'
import { useCreateEmployee } from '../hooks/useCreateEmployee'
import { useCheckTestGroupLimit } from '../hooks/useCheckTestGroupLimit'
import { validatePassword } from '../helpers/validatePassword'
import { validateMail } from "../helpers/validateEmail"
//import { useIsCompanyActivated } from '../hooks/useIsCompanyActivated'
//import { MatchProps } from '../components/authRoutes/authRoutes'
import ErrorNoLicense from "../screens/ErrorNoLicense";

import { useUserContext } from "../firebase/FirebaseContext"

interface Props {
  match: match<{ id: string }>
}



const EmployeeRegistrationScreen: React.FC<Props> = ({ match }) => {

  const history = useHistory();

  const isTestGroupLimitOk = useCheckTestGroupLimit(match.params.id!);
  //const isCompanyActivated = useIsCompanyActivated(match.params.id!)

  const [alreadyRegistered, setAlreadyRegistered] = useState(false)
  const [awaitingResults, setAwaitingResults] = useState(false)
  const [gender, setGender] = useState<{ value: string }>({ value: 'empty' })
  const [genderError, setGenderError] = useState(false)
  const [firstName, setFirstName] = useFormFieldState('');
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useFormFieldState('');
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useFormFieldState('');
  const [emailError, setEmailError] = useState(false)
  const [department, setDepartment] = useState<{ value: string }>({ value: 'empty' });
  const [departmentError, setDepartmentError] = useState(false)
  const [jobTitle, setJobTitle] = useFormFieldState('');
  const [jobTitleError, setJobTitleError] = useState(false)
  const [password, setPassword] = useFormFieldState('');
  const [passwordError, setPasswordError] = useState(false)
  const [confirmPassword, setConfirmPassword] = useFormFieldState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  //const [pressFaq, setFaqPress] = useState(false);
  const [formSubmittedToggle, setFormSubmittedToggle] = useState(false)

  const createEmployee = useCreateEmployee();
  const { logIn, setIsLoggedIn } = useUserContext()

  //const history = useHistory();
  const { i18n, t } = useTranslation(['employeeRegistration', 'basics', 'departments'])
  const en = i18n.language.includes('en') ? '/en' : ''

  const genders = [
    { value: "male", label: i18n.language.includes('de') ? "Männlich" : 'Male' },
    { value: "female", label: i18n.language.includes('de') ? "Weiblich" : 'Female' },
    { value: "other", label: i18n.language.includes('de') ? "Divers" : 'Diverse' },
    { value: "noinformation", label: i18n.language.includes('de') ? "Keine Angabe" : 'No information' }]

  const departments = [
    { value: "accounting", label: t('departments:accounting') },
    { value:"design", label: t('departments:design') },
    { value: "edp", label: t('departments:edp') },
    { value: "purchasing", label: t('departments:purchasing') },
    { value:"export", label: t('departments:export') },
    { value:"finance", label: t('departments:finance') },
    { value:"randd", label: t('departments:randd') },
    { value:"ceo", label: t('departments:ceo') },
    { value:"import", label: t('departments:import') },
    { value:"it", label: t('departments:it') },
    { value:"customerservice", label: t('departments:customerservice') },
    { value:"warehouse", label: t('departments:warehouse') },
    { value:"logistics", label: t('departments:logistics') },
    { value:"marketing", label: t('departments:marketing') },
    { value:"materials", label: t('departments:materials') },
    { value:"hr", label: t('departments:hr') },
    { value:"planing", label: t('departments:planing') },
    { value:"publicrelations", label: t('departments:publicrelations') },
    { value:"production", label: t('departments:production') },
    { value:"secretary", label: t('departments:secretary') },
    { value:"technicalsupport", label: t('departments:technicalsupport') },
    { value:"sales", label: t('departments:sales') },
    { value:"shipping", label: t('departments:shipping') },
    { value:"other", label: t('departments:other') }
  ]

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setAwaitingResults(true)

    try {
      var employeeLanguage:'de'|'en'|'fr' = 'de'
      if(i18n.language.includes('en')) {
        employeeLanguage = 'en'
      } else if(i18n.language.includes('fr')) {
        employeeLanguage = 'fr'
      }
      const create = await createEmployee({
        firstName,
        lastName,
        gender: gender ? gender.value as 'male' | 'female' | 'other' | 'noinformation' : null,
        termsAccepted: Date.now(),
        testGroup: match.params.id!,
        email: email,
        language: employeeLanguage,
        department: department ? department.value: null,
        jobTitle,
        password,
        passwordConfirmation: confirmPassword,
        role: 'EMPLOYEE',
      });
      if (create && create.error) {
        setAwaitingResults(false)
        setGenderError(true)
        setFirstNameError(true)
        setLastNameError(true)
        setEmailError(true)
        setDepartmentError(true)
        setJobTitleError(true)
        setPasswordError(true)
        setConfirmPasswordError(true)


        if (firstName !== "") setFirstNameError(false)
        if (gender !== null && gender.value !== 'empty') setGenderError(false)
        if (lastName !== "") setLastNameError(false)
        if (email !== "" && validateMail(email)) setEmailError(false)
        if (department !== null && department.value !== 'empty') setDepartmentError(false)
        if (jobTitle !== "") setJobTitleError(false)
        if (validatePassword(password)) setPasswordError(false)
        if (confirmPassword !== '' && password === confirmPassword) setConfirmPasswordError(false)
        setFormSubmittedToggle(!formSubmittedToggle)
        return
      }
      if (create && create.success) {
        setIsLoggedIn(true)
        setAlreadyRegistered(false)
        setAwaitingResults(true)
        await logIn(email, password)
        history.push('/employee')
      }
    } catch (e: any) {
      setAwaitingResults(false)
      setGenderError(true)
      setFirstNameError(true)
      setLastNameError(true)
      setEmailError(true)
      setDepartmentError(true)
      setJobTitleError(true)
      setPasswordError(true)
      setConfirmPasswordError(true)

      if (firstName !== "") setFirstNameError(false)
      if (gender !== null && gender.value !== 'empty') setGenderError(false)
      if (lastName !== "") setLastNameError(false)
      if (email !== "" && validateMail(email)) setEmailError(false)
      if (department !== null && department.value !== 'empty') setDepartmentError(false)
      if (jobTitle !== "") setJobTitleError(false)
      if (validatePassword(password)) setPasswordError(false)
      if (confirmPassword !== '' && password === confirmPassword) setConfirmPasswordError(false)
      if (e.code === "auth/email-already-in-use") setAlreadyRegistered(true)
    }
  }

  const bodyContentFirst = (
    <>
      {/*Mobile*/}
      <div className="lg:hidden xl:hidden">
        <div className="mb-16 mt-20">
          <H1 color="uw-teal">{t('texts.headline')}</H1>
        </div>
        <div className="mb-8">
          <H2 color="uw-teal">{t('texts.secondHeadline')}</H2>
        </div>
        <div className="mb-8">
          <Text color="text-uw-grey">
            {t('texts.secondHeadlineExplanationOne')}
            <br />
            <br />
            {t('texts.secondHeadlineExplanationTwo')}
          </Text>
        </div>
      </div>
      {/*Desktop*/}
      <div className="hidden lg:block xl:block">
        <div className="mb-24 mt-32 w-2/3">
          <H1 color="uw-teal">{t('texts.headline')}</H1>
        </div>
        <div className="flex flex-row justify-between relative z-10">
          <div className="mr-8 w-1/3">
            <H2 color="uw-teal">
              {t('texts.secondHeadline')}
            </H2>
          </div>
          <div className="w-7/12 mb-12">
            <Text color="text-uw-grey" one>
              {t('texts.secondHeadlineExplanationOne')}
              <br />
              <br />
              {t('texts.secondHeadlineExplanationTwo')}
            </Text>
          </div>
        </div>
      </div>
    </>
  )

  const bodyContentGreenBackground = (
    <>
      <div className="mb-10 lg:mt-12 xl:mt-12 lg:mb-16 xl:mb-16">
        <H2 color="white">{t('texts.thirdHeadline')}</H2>
      </div>
      <form>
        <div
          className="lg:flex lg:flex-row lg:justify-between lg:mx-24 xl:flex xl:flex-row xl:justify-between xl:mx-24">
          <div
            className="lg:flex lg:flex-col lg:flex-grow lg:mr-8 xl:flex xl:flex-col xl:flex-grow xl:mr-8 lg:w-1/2 xl:w-1/2">
            <Dropdown autoFocus={genderError} error={genderError} formSubmitted={formSubmittedToggle}
              title={t('texts.dropdownTitle')} options={genders} value={gender}
              setValue={gender => setGender(gender as SetStateAction<{ value: string }>)} />
            <InputField autoFocus={!genderError && firstNameError} error={firstNameError}
              errorMsg="errors.firstName"
              formSubmitted={formSubmittedToggle} value={firstName}
              nameSpace="employeeRegistration"
              onChangeHandler={setFirstName}
              type="text">{t('form.prename')}</InputField>
            <InputField autoFocus={!genderError && !firstNameError && lastNameError} error={lastNameError}
              errorMsg="errors.lastName" formSubmitted={formSubmittedToggle}
              value={lastName}
              nameSpace="employeeRegistration" onChangeHandler={setLastName}
              type="text">{t('form.surname')}</InputField>
            <InputField autoFocus={!genderError && !firstNameError && !lastNameError && emailError}
              error={emailError}
              errorMsg="errors.mail" formSubmitted={formSubmittedToggle}
              value={email}
              nameSpace="employeeRegistration" onChangeHandler={setEmail}
              type="email">{t('form.email')}</InputField>
              {alreadyRegistered && <div className="mt-3">
                <div className="relative">
                  <div className="ml-4 arrow-up absolute top-minus11" />
                </div>
                <input
                  className="text-base bg-uw-errorRed flex w-full p-2 rounded-lg font-text leading-text font-normal text-uw-errorRedText"
                  defaultValue={t('basics:errors.mailInUse')}
                  disabled={true}
                />
              </div>}
          </div>
          <div className="lg:flex lg:flex-col xl:flex xl:flex-col lg:w-1/2 xl:w-1/2">


            <Dropdown autoFocus={!genderError && !firstNameError && !lastNameError && emailError && !departmentError} error={departmentError} errorMessage={t('errors.department')} formSubmitted={formSubmittedToggle}
              title={t('form.department')} options={departments} value={department} 
              setValue={department => setDepartment(department as SetStateAction<{ value: string }>)} />

            <InputField
              autoFocus={!genderError && !firstNameError && !lastNameError && emailError && !departmentError && !jobTitleError}
              error={jobTitleError} errorMsg="errors.jobtitle"
              formSubmitted={formSubmittedToggle}
              value={jobTitle} nameSpace="employeeRegistration" onChangeHandler={setJobTitle}
              type="text">{t('form.jobtitle')}</InputField>


            <InputField
              autoFocus={!genderError && !firstNameError && !lastNameError && !emailError&& !departmentError && !jobTitleError && passwordError}
              error={passwordError} errorMsg="errors.password"
              formSubmitted={formSubmittedToggle}
              value={password} nameSpace="employeeRegistration" onChangeHandler={setPassword}
              type="password">{t('form.password')}</InputField>
            <div className="lg:mb-2 xl:mb-2" />
            <InputField
              autoFocus={!genderError && !firstNameError && !lastNameError && !emailError && !departmentError && !jobTitleError&& !passwordError && confirmPasswordError}
              error={confirmPasswordError} errorMsg="errors.passwordConfirmation"
              formSubmitted={formSubmittedToggle}
              value={confirmPassword} nameSpace="employeeRegistration"
              onChangeHandler={setConfirmPassword}
              type="password">{t('form.passwordConfirmation')}</InputField>
            <div className="mb-1" />
              <div className="mt-8 lg:mt-8 lg:max-w-sm xl:mt-8 xl:max-w-sm">
                <Button backgroundWhite onClick={handleSubmit} title={t('texts.registerButton')}
                  type="submit" />
              </div>
              <div className="mt-4 ml-2">
                  <a className="flex" href={`https://luksit.de/datenschutz/${en}#luksit-app`} rel="noopener noreferrer" target="_blank">
                      <p className="font-text hover:underline leading-none text-base text-white">{t('form.dataPrivacy')}</p>
                  </a>
              </div>
            </div>
        </div>
      </form>
    </>
  )

  const bodyContentSecond = (
    <>
        {/*Mobile*/}
        <div className="lg:hidden xl:hidden relative z-10">
            <H3 color="uw-teal">{t('basics:texts.stillQuestions')}</H3>
            <div className="m-8"/>
            <Text color="text-uw-grey">{t('basics:texts.faqText')}</Text>
            <div className="m-6"/>
            <Button backgroundWhite borderColor onClick={()=> window.open(window.location.protocol+ "//" +window.location.host+"/employee/faq", "_blank")} title={t('basics:buttons.buttonFaq')} //siehe Ticket SD-235
                    type="button"/>
        </div>
        {/*Desktop*/}
        <div className="hidden lg:block xl:block relative z-10 mt-12">
          <div className="flex flex-row justify-between mr-20 mb-8">
              <H3 color="uw-teal">{t('basics:texts.stillQuestions')}</H3>
              <div className="flex flex-col">
                  <div className="w-24rem">
                      <Text color="text-uw-grey" one>{t('basics:texts.faqText')}</Text>
                  </div>
                  <div className="m-4"/>
                  <div className="mb-16">
                      <Button backgroundWhite borderColor onClick={()=> window.open(window.location.protocol+ "//" +window.location.host+"/employee/faq", "_blank")} //siehe Ticket SD-235
                              title={t('basics:buttons.buttonFaq')}
                              type="button"/>
                  </div>
              </div>
            </div>
        </div>
    </>
  )

  

  return (
    isTestGroupLimitOk === null ?
      <>
        <Container background="white" headerFooter login>
          <div className="h-screen flex justify-center">
            <div className="flex flex-col align-middle justify-center text-center">
              <div className="lg:hidden xl:hidden">
                <H2 color="uw-teal">
                  {t('basics:validation.validationOne')}
                  <br />
                  {t('basics:validation.validationTwo')}
                </H2>
              </div>
              <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
                <H2 color="uw-teal">
                  {t('basics:validation.validationOne')}
                  <br />
                  {t('basics:validation.validationTwo')}
                </H2>
              </div>
              <div className="mt-12 flex justify-center">
                <DotLoader color="#008080" loading size={150} />
              </div>
            </div>
          </div>
        </Container>
      </>
      :
      (
        awaitingResults ?
          <>
            <Container background="white" headerFooter login>
              <div className="h-screen flex justify-center">
                <div className="flex flex-col align-middle justify-center text-center">
                  <div className="lg:hidden xl:hidden">
                    <H2 color="uw-teal">
                      {t('basics:validation.validationOne')}
                      <br />
                      {t('basics:validation.validationTwo')}
                    </H2>
                  </div>
                  <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
                    <H2 color="uw-teal">
                      {t('basics:validation.validationOne')}
                      <br />
                      {t('basics:validation.validationTwo')}
                    </H2>
                  </div>
                  <div className="mt-12 flex justify-center">
                    <DotLoader color="#008080" loading size={150} />
                  </div>
                </div>
              </div>
            </Container>
          </> : (
            isTestGroupLimitOk ?
              <>
                <Container
                  background="white"
                  bodyContentFirst={bodyContentFirst}
                  bodyContentGreenBackground={bodyContentGreenBackground}
                  bodyContentSecond={bodyContentSecond}
                  greenBackground
                  headerFooter
                  login
                />
              </> : <ErrorNoLicense />
             /*<ErrorComponent buttonTitle={[t('basics:buttons.startButton'), t('basics:buttons.buttonContact')]}
               errorMsg={t('errors.errorLicense')}
               buttonLink={["/", "/contact"]} />*/
          )
      )
  )
}

export default EmployeeRegistrationScreen;