//React
  import React, { useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import IndividualResultsBar from "../layout/IndividualResultsBar"
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useParams } from "react-router"
import { IUserData } from '../../hooks/useUpdateProfile'
//import { UseGetEmployeeProfile } from '../../hooks/useGetEmployeeProfile'
import { UseGetMatchingEmployee } from '../../hooks/useGetMatchingEmployee'
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformStrenghAndRisksToDataObject, ICompetenceData } from '../../helpers/transform'
import LoadingScreenNew from "../layout/LoadingScreenNew"
//useTranslation
import { useTranslation } from "react-i18next"
import EmployeeDevelopment from '../layout/EmployeeDevelopment'
import SiteNotFoundScreen from "../../screens/SiteNotFoundScreenDS"



const CompanyIndividualDevelopmentView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const { t, i18n } = useTranslation(['companyMatching', 'employeeResults', 'departments']);
  interface ParamTypes {
    uid: string,
    profileid: string
  }

  const { profileid, uid } = useParams<ParamTypes>()


  const {companyUser} = useUserContext()

  let testGroups = companyUser!.testGroups

  //const [profileCompetenceData, setProfileCompetenceData] = useState<IUserData>({})
  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [userName, setUserName] = useState('')
  const [userAdditionalInfos, setUserAdditionalInfos] = useState('')

  const { resultLocalesStandard, resultLocalesStrengthsAndRisks } = useUserContext()
  //const strengthAndRiskLanguage = i18n.language.includes('de') ? 'strengthsAndRisksDE' : 'strengthsAndRisksEN'
  var strengthAndRiskLanguage = 'strengthsAndRisksDE'
  if(i18n.language.includes('en')) {
    strengthAndRiskLanguage = 'strengthsAndRisksEN'
  } else if(i18n.language.includes('fr')) {
    strengthAndRiskLanguage = 'strengthsAndRisksFR'
  }


  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformStrenghAndRisksToDataObject(resultLocalesStrengthsAndRisks[strengthAndRiskLanguage])
  }

  //const getProfile = UseGetEmployeeProfile()

  window.scrollTo({top: 0, behavior: 'smooth'});

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getAllMatchingEmployee = UseGetMatchingEmployee()
        //let profile = await getProfile(uid)
        let matchEmployee = await getAllMatchingEmployee(uid, testGroups)
        if(matchEmployee) {
          //setProfileCompetenceData(profile.data)
          setUserCompetenceData(matchEmployee.data)
          setUserName(matchEmployee.firstName+' '+matchEmployee.lastName)
          if(matchEmployee.department && matchEmployee.jobTitle) {
            setUserAdditionalInfos(matchEmployee?.jobTitle +' | '+ t('departments:'+String(matchEmployee.department)))
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileid, uid, testGroups, t])

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY)

  const breadCrumbHome = {
  
    name: 'home',
    href: '/company/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: userName + ', ' + userAdditionalInfos,
      href: '/company/employee/development/' + uid,
      current: true
    }
  ]

  if(companyUser && companyUser!.empoloyeeAccess !== true) {
    return (
      <>
        <SiteNotFoundScreen />
      </>
    )
  }

  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-5">
            <div className="col-span-3">
              <div className="pt-2 pb-4">
                <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
              </div>
              <div className='flex items-center'>
                <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">{t('companyEmployeeResults:headline.EmployeeResultsHeadline')}</h1>
              </div>
            </div>
            <div className="mt-8">
              <div className="col-span-2">
               <p className="text-xl text-uw-teal font-medium"></p>
               <p className="text-sm text-gray-600"></p>
              </div>
            </div>
            <div className="mt-10">
              <div className="col-span-1 justify-self-end">
                <a href={'/company/requirementprofiles/profile/'+uid}>
                  <button
                    type="button"
                    className="items-center px-4 py-2 border border-transparent shadow-sm text-sm text-uw-teal border border-uw-teal font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">{t('employeeResults:basics.buttonRequirementProfile')}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"> 
            <div className="mt-8 mb-8">
            <IndividualResultsBar active={[false, true]}></IndividualResultsBar>
            </div>
            <EmployeeDevelopment competenceData={competenceData.category[2]} userData={userCompetenceData} points={userCompetenceData['g8t_category2']} />
            <EmployeeDevelopment competenceData={competenceData.category[5]} userData={userCompetenceData} points={userCompetenceData['g8t_category5']} />
            <EmployeeDevelopment competenceData={competenceData.category[7]} userData={userCompetenceData} points={userCompetenceData['g8t_category7']} />
            <EmployeeDevelopment competenceData={competenceData.category[3]} userData={userCompetenceData} points={userCompetenceData['g8t_category3']} />
            <EmployeeDevelopment competenceData={competenceData.category[4]} userData={userCompetenceData} points={userCompetenceData['g8t_category4']} />
            <EmployeeDevelopment competenceData={competenceData.category[8]} userData={userCompetenceData} points={userCompetenceData['g8t_category8']} />
            <EmployeeDevelopment competenceData={competenceData.category[6]} userData={userCompetenceData} points={userCompetenceData['g8t_category6']} />
            <EmployeeDevelopment competenceData={competenceData.category[1]} userData={userCompetenceData} points={userCompetenceData['g8t_category1']} />
            

            <div className="pt-8"> 
              <a href='/company/dashboard'>
                <button
                  type="button"
                  className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                </button>
              </a>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default CompanyIndividualDevelopmentView