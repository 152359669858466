import React, { useEffect, useState } from 'react';
import classnames from 'classnames'
import Header from "./Header";
import Footer from "./Footer";
import GreenBackground from "./GreenBackground";
import { useTranslation } from "react-i18next";

interface Props {
  background: string;
  bodyContentFirst?: object
  bodyContentGreenBackground?: object
  bodyContentSecond?: object
  greenBackground?: boolean;
  greenBackgroundLessUpperPart?: boolean;
  headerFooter: boolean;
  header?: boolean
  login?: boolean
  logout?: boolean
}

const Container: React.FC<Props> = ({ background, bodyContentFirst, bodyContentGreenBackground, bodyContentSecond, children, greenBackground, greenBackgroundLessUpperPart, header, headerFooter, login, logout }) => {

  const { i18n } = useTranslation('footer')
  const [language, setLanguage] = useState<string>('de')

  const languageSetter = (languageCode: string) => {
    setLanguage(languageCode);
  }

  useEffect(() => {
    if (i18n.language.includes('de')) {
      setLanguage('de')
    } else if (i18n.language.includes('en')) {
      setLanguage('en')
    } else if(i18n.language.includes('fr')) {
      setLanguage('fr')
    }
    else if (i18n.language.includes('fr')) {
      setLanguage('fr');
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (language === "de") i18n.changeLanguage('de')
    if (language === "en") i18n.changeLanguage('en')
    if (language === "fr") i18n.changeLanguage('fr')  
  }, [language, i18n])

  const body = !greenBackground ?
    <div className={`flex bg-${background} h-full flex-grow justify-center mt-12`}>
      <div className={`container px-6`}>
        <div className="w-full ">{children}</div>
      </div>
    </div> :
    <>
      <div className={classnames("flex justify-center", {
        'mt-12 flex-grow': !greenBackgroundLessUpperPart
      })}>
        <div className={`container bg-${background} px-6`}>
          <div className="w-full">{bodyContentFirst}</div>
        </div>
      </div>
      <GreenBackground greenBackgroundLessUpperPart={greenBackgroundLessUpperPart}>{bodyContentGreenBackground}</GreenBackground>
      <div className="flex flex-grow justify-center">
        <div className={`container bg-${background} px-6 pb-2`}>
          <div className="w-full">{bodyContentSecond}</div>
        </div>
      </div>
    </>

  if (header === true) {
    return (
      <div className="flex flex-1 flex-col min-h-screen">
        <div className="flex">
          <Header language={language} languageSetter={languageSetter} login={login} logout={logout} />
        </div>
        {body}
      </div>
    )
  } else {
    return (headerFooter ?
      <div className="min-h-screen flex flex-grow flex-col">
        <div className="flex">
          <Header language={language} languageSetter={languageSetter} login={login} logout={logout} />
        </div>
        {body}
        <Footer language={language} languageSetter={languageSetter} />
      </div>
      :
      <>
        {body}
      </>
    )
  }
};


export default Container;
