//React
import React, { Fragment, useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import MatchingComparison from "../layout/MatchingComparison"
import MatchingComparisonViewPDF from '../companyDashbaord/MatchingComparisonViewPDF'
import { MailIcon, CalendarIcon, QuestionMarkCircleIcon, ChevronLeftIcon, CheckIcon, ChevronDownIcon, DocumentDownloadIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed'
import { useParams } from "react-router"
import { IUserData } from '../../hooks/useUpdateProfile'
import { UseGetJob, IJob } from '../../hooks/useGetJob'
import { EStatus } from '../../hooks/useGetJobStats'
import { UseGetMatchingCandidate, IMatchingCandidate } from '../../hooks/useGetMatchingCandidate'
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformToDataObject, ICompetenceData } from '../../helpers/transform'
import LoadingScreenNew from "../../components/layout/LoadingScreenNew"
import { Listbox, Transition } from '@headlessui/react'
//useTranslation
import { useTranslation } from "react-i18next"
import SiteNotFoundScreen from "../../screens/SiteNotFoundScreen"
import { Timestamp } from 'firebase/firestore'
import { scoreEmployees } from '../../helpers/scoreEmployees'
import { useChangeCandidateStatus } from '../../hooks/useChangeCandidateStatus'

import ReactDOMServer from 'react-dom/server';
// @ts-ignore
import html2pdf from 'html2pdf.js'

const RecruitingIndividualComparisonView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const { t, i18n } = useTranslation(['companyMatching','companyRecruitingIndividualComparison']);
  interface ParamTypes {
    uid: string,
    jobid: string
  }

  const publishingOptions = [
    { id: EStatus.NEW, name: t('companyRecruitingIndividualComparison:candidateStatus.NEW'), current: false },
    { id: EStatus.INTERVIEW, name: t('companyRecruitingIndividualComparison:candidateStatus.INTERVIEW'), current: false },
    { id: EStatus.OFFER, name: t('companyRecruitingIndividualComparison:candidateStatus.OFFER'), current: false },
    { id: EStatus.HIRED, name: t('companyRecruitingIndividualComparison:candidateStatus.HIRED'), current: false },
    { id: EStatus.FIRED, name: t('companyRecruitingIndividualComparison:candidateStatus.REJECTED'), current: false },
  ]

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const [selected, setSelected] = useState({name: '', current: true })

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_RECRUITING)

  const changeCandidateStatus = useChangeCandidateStatus()

  const handleChangeCandidateStatus = (e: any) => {
    setSelected(e)
    
    if(candidate) {
      if(candidate.status === e.id)
        return

      let newStatus = { date: new Timestamp(Math.floor(Number(Date.now()) / 1000), 0), status: e.id }
      candidate.statusArr.push(newStatus)
      candidate.status = e.id
      changeCandidateStatus(candidate.id, newStatus, jobid)

    }
  }

  const { jobid, uid } = useParams<ParamTypes>()
  const {companyUser} = useUserContext()

  let testGroups = companyUser!.testGroups

  const [profileCompetenceData, setProfileCompetenceData] = useState<IUserData>({})
  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [userName, setUserName] = useState('')
  //const [job, setJob] = useState<IJob>()
  const [candidate, setCandidate] = useState<IMatchingCandidate | undefined>()
  const [jobName, setJobName] = useState('')
  const [applyDate, setApplyDate] = useState<Timestamp>()
  const [score, setScore] = useState('')
  const [userMail, setUserMail] = useState<string>('')
  //const [userAdditionalInfos, setUserAdditionalInfos] = useState('')

  const { resultLocalesStandard } = useUserContext()
  //const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'
  var standardLanguage = 'standardDE';
  if(i18n.language.includes('en')) {
    standardLanguage = 'standardEN'
  } else if(i18n.language.includes('fr')) {
    standardLanguage = 'standardFR'
  }

  //const targetRef = useRef();

  const downloadPDF = () => {
    const dateLocale = i18n.language.includes('en') ? 'en-EN' : i18n.language.includes('fr') ? 'fr-FR' : 'de-DE'
    const personalData = {
      name: userName,
      email: userMail,
      position: jobName,
      date: applyDate?.toDate().toLocaleDateString(dateLocale),
      score: score,
    };
  
    const printElement = ReactDOMServer.renderToString(
      <MatchingComparisonViewPDF
        personalData={personalData}
        competenceData={competenceData}
        userData={userCompetenceData}
        profileData={profileCompetenceData}
        score={score}
      />
    );
  
    const options = {
      margin: 20,
      filename: `Jobmatching_${userName}.pdf`,
      image: { type: 'jpeg', quality: 0.9 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    html2pdf()
      .from(printElement)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
  
        // Seitenzahlen einfügen
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);

          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(8);
          pdf.setTextColor(0, 128, 128);

          pdf.text(`${i} | ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
        }
      })
      .save();
  };

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
  }

  //console.log(competenceData)
  const getMatchingCandidate = UseGetMatchingCandidate()
  const getJob = UseGetJob()
  useEffect(() => {
    
    const requestProfile = async () => {
      try {
        let job: IJob = await getJob(jobid, false, 'LIST')
        if(job) {
          setJobName(job.name)
        }
        let matchEmployee : IMatchingCandidate = await getMatchingCandidate(uid, testGroups, jobid)
        if(job && matchEmployee) {
          let jobOption = publishingOptions.find(element => element.id === matchEmployee.status)
          setSelected(jobOption!)
          let scoredCandidate = scoreEmployees(job, [matchEmployee])
          setApplyDate(matchEmployee.jobs[jobid].date)
          setScore(scoredCandidate[0].score)
          setUserMail(String(matchEmployee.email))
          setProfileCompetenceData(job.data)
          setUserCompetenceData(matchEmployee.data)
          setUserName(matchEmployee.firstName+' '+matchEmployee.lastName)
          setCandidate(matchEmployee)
          //console.log(matchEmployee)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[jobid, uid, testGroups])

  //setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.COMPANY_MATCHING)

  const breadCrumbHome = {
  
    name: 'home',
    href: '/recruiting/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: jobName,
      href: '/recruiting/jobprofile/'+jobid,
      current: false
    },
    {
      name: t('companyRecruitingIndividualComparison:headlineBreadcrumb'),
      href: '/recruiting/jobprofile/'+jobid+'/'+uid,
      current: true
    }
  ]

const [ isExpanded, setExpanded ] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

  if(companyUser && companyUser!.empoloyeeAccess !== true) {
    return (
      <>
        <SiteNotFoundScreen />
      </>
    )
  }



  
  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <div className="grid grid-cols-2">
              <div className='flex items-center pb-2'>
                <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">{userName}</h1>
                <p className="text-2xl text-uw-teal font-medium"></p>
              </div>
            <div className="mt-5 flex xl:mt-0 xl:ml-4 place-content-end">

            <div className="sm:ml-3 relative">
              <button
                onClick={downloadPDF}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <DocumentDownloadIcon className="-ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                PDF
              </button>
            </div>

              <span className="hidden sm:block ml-3">
              <a
                href={'mailto:'+userMail+'?subject=Job '+jobName}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {t('companyRecruitingIndividualComparison:button')}
                </button>
              </a>
              </span>

              <div className="sm:ml-3 relative">
                <Listbox value={selected} onChange={handleChangeCandidateStatus}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                      <div className="relative">
                        <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
                          <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-white">
                            <div className="relative inline-flex items-center bg-uw-teal py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              <p className="ml-2.5 text-sm font-medium">{selected.name}</p>
                            </div>
                            <Listbox.Button className="relative inline-flex items-center bg-uw-teal p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-600">
                              <span className="sr-only">Change published status</span>
                              <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </Listbox.Button>
                          </div>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="origin-top-right absolute left-0 mt-2 -mr-1 z-10 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                            {publishingOptions.map((option) => (
                              <Listbox.Option
                                key={option.name}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-uw-teal' : 'text-gray-900',
                                    'cursor-default select-none relative p-4 text-sm'
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <div className="flex flex-col">
                                    <div className="flex justify-between">
                                      <p className={selected ? 'font-semibold' : 'font-normal'}>{option.name}</p>
                                      {selected ? (
                                        <span className={active ? 'text-white' : 'text-uw-teal'}>
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </div>
                                    
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>

              {/* Dropdown */}
              {/*<Menu as="div" className="ml-3 relative sm:hidden">
                <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                  More
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#" 
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          Edit
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          View
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
                      </Menu>*/}
            </div>
            </div>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

              <div className="grid-span-3 grid grid-cols-10">
                <div className="flex items-center text-sm text-gray-500 col-span-2">
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                  <p className="text-sm text-gray-600">{t('companyRecruitingIndividualComparison:candidateInfo.applicationDate') + ' ' + String(applyDate?.toDate().toLocaleDateString('de-DE'))}</p>
                </div>

                {/* AUSBAUSTUFE LÖSCHUNG @Martin kannst du erstmal ausblenden*/}
                {/*<div className="flex items-center text-sm text-gray-500 col-span-2">
                  <TrashIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-red-400" aria-hidden="true" />
                  <p className="text-sm text-gray-600">{t('companyRecruitingIndividualComparison:candidateInfo.deleteCandidateDate')}04.03.2022</p>
                </div>
                */}
              </div> 
            
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                </div>
              </div>
            </div>

            {/*  AUSBAUSTUFE Beispiel für FARBCODE PASSUNG            
            <div className="relative">
              <div className="absolute right-0 mr-9 text-2xl text-uw-teal font-medium box-border divide-x divide-uw-teal rounded-b-lg px-4 py-4 bg-green-200">
              68 %                 
              </div>
            </div> 
            */}

            <div className="relative">
              <div className="absolute right-0 mr-9 text-2xl text-uw-teal font-medium box-border divide-x divide-uw-teal rounded-b-lg px-4 py-4 bg-gray-100">
              { score } %
              </div>
            </div>

            <div className='flex items-center mt-10'>
              <h2 className="lg:text-2xl text-xl font-medium leading-tight text-uw-teal pb-2">{t('companyRecruitingIndividualComparison:headline')}</h2>
              <div {...getToggleProps({onClick: handleOnClick})} className="flex justify-start pl-2">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400 mb-2" aria-hidden="true" />
              </div>
            </div>
            <div {...getCollapseProps()}>
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3">
                <div className="flex text-md text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {t('comparison.infoText')}
                  </span>
                </div>
              </div>
            </div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[25]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[2]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[35]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[19]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[6]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[38]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[29]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[27]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[20]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[9]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[3]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[37]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[31]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[26]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[21]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[17]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[13]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[15]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[4]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[36]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[5]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[30]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[23]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[34]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[10]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[33]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[28]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[24]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[14]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[12]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[7]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[39]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[8]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[1]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[32]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[22]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[18]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[16]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[11]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pt-8"> 
              <a href={'/recruiting/jobprofile/'+jobid}> {/* @Martin bitte Link anpassen */}
                <button
                  type="button"
                  className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                </button>
              </a>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default RecruitingIndividualComparisonView