//React
import React from 'react'
import { useTranslation } from "react-i18next";
//Hooks


interface Props {
  competenceData: IState,
  userData: any,
  profileData: any,
  employeeView?: boolean
}
export interface IState {
  description?: string,
  name?: string,
  high?: string,
  low?: string,
  level?: any,
  element?: string
}

const MatchingComparisonPDF: React.FC<Props> = ({competenceData, userData, profileData, employeeView=false}) => {
  
  const { t } = useTranslation(['companyMatching']);

  let textActualProfile = t('comparison.profileActual')

  if(employeeView===true) {
    textActualProfile = ''
  }

  let matchClassObj: any = {
    1: 'col-span-1 grid justify-items-center content-center rounded-l-md',
    2: 'col-span-1 grid justify-items-center content-center',
    3: 'col-span-1 grid justify-items-center content-center',
    4: 'col-span-1 grid justify-items-center content-center',
    5: 'col-span-1 grid justify-items-center content-center rounded-r-md'
  }

  for(let i:number = 1; i<=5; i++) {
    if(profileData['g8t_'+competenceData.element] === i.toString()) {
      if((i-1)>=1)
        matchClassObj[(i-1)] += ' matchingBackgroundNearMatch'
      matchClassObj[i] += ' matchingBackgroundMatch'
      if((i+1<=5))
        matchClassObj[(i+1)] += ' matchingBackgroundNearMatch'
    }
  }

  let boxCss = "bg-gray-50 shadow overflow-hidden rounded-lg lg:rounded-md mb-3"

  if(competenceData.level[profileData['g8t_'+competenceData.element]])
    boxCss += " border-l-4 border-uw-teal"
  

  return (
      <div className={boxCss} style={{ breakInside: 'avoid' }}>
      
        <div className="flex">
          <button className="bg-gray-50 flex-1" >
            <div className=" py-4 px-4 sm:px-6">
                <div className="items-center px-4 mb-2 text-left">
                    <p className="text-md font-medium text-uw-teal mb-4 mt-2 md:mt-0 md:mb-0">{competenceData.name}</p>
                    <div className='hidden items-center md:flex -mb-1 mt-1.5 md:col-span-2'>
                    </div>
                </div>
              <div className="md:grid md:grid-cols-7">
                <div className="md:col-span-3 self-center">
                  <p className="px-4 pt-1 text-xs md:text-xs font-small text-uw-teal md:text-right text-left w-3/4 md:w-full">{competenceData.low}</p>
                </div>
                <div className="md:col-span-1 items-center grid content-center py-4 md:py-0 px-4 md:px-0 mt-4">
                  <div className="grid grid-cols-5 h-6 box-border border-solid border-uw-teal border divide-x divide-uw-teal rounded-md">
                    <div className={matchClassObj['1']}> 
                      {(userData && userData['g8t_'+competenceData.element] === 1)?<div className="w-2 h-2 rounded-full bg-uw-teal"></div>:null} 
                    </div> 
                    <div className={matchClassObj['2']}> 
                      {(userData && userData['g8t_'+competenceData.element] === 2)?<div className="w-2 h-2 rounded-full bg-uw-teal"></div>:null} 
                    </div> 
                    <div className={matchClassObj['3']}> 
                      {(userData && userData['g8t_'+competenceData.element] === 3)?<div className="w-2 h-2 rounded-full bg-uw-teal"></div>:null} 
                    </div> 
                    <div className={matchClassObj['4']}> 
                      {(userData && userData['g8t_'+competenceData.element] === 4)?<div className="w-2 h-2 rounded-full bg-uw-teal"></div>:null} 
                    </div> 
                    <div className={matchClassObj['5']}> 
                      {(userData && userData['g8t_'+competenceData.element] === 5)?<div className="w-2 h-2 rounded-full bg-uw-teal"></div>:null} 
                    </div> 
                  </div>
                </div>
                <div className="md:col-span-3 self-center flex">
                  <div className='w-1/4 md:hidden'></div>
                  <p className="px-4 pt-1 text-xs md:text-xs font-small text-uw-teal md:text-left text-right md:w-full w-3/4">{competenceData.high}</p>
                </div>
              </div>
              <div className='items-center flex -mb-1 mt-1.5 py-2 px-4 md:col-span-2 md:hidden'>
              </div>
            </div>
          </button>
        </div>

        <div>

        <div className="bg-white border-b border-gray-200 sm:px-6 mb-2 ml-8 mr-8"></div>


          <div className="block flex-1 px-4 pb-0 pt-2 md:grid md:grid-cols-6" >

            <div className="md:col-span-1">
            <p className="px-6 text-xs font-medium text-uw-teal">{textActualProfile}</p>
            </div>

            <div className="md:col-span-5">
              <p className="px-6 text-xs font-small text-gray-600 ">{competenceData.level[userData['g8t_'+competenceData.element]]}</p>
            </div>

          </div>
          <div className="block flex-1 px-4 pb-5 pt-2 md:grid md:grid-cols-6" >  
            
            <div className="md:col-span-1">
            <p className="px-6 text-xs font-medium text-uw-teal">{(competenceData.level[profileData['g8t_'+competenceData.element]])?t('comparison.profileTarget'):''}</p>
            </div>

            <div className="md:col-span-5">
              <p className="px-6 text-xs font-small text-gray-600 ">{competenceData.level[profileData['g8t_'+competenceData.element]]}</p>
            </div>

          </div>
        </div>
      </div>
  )
}

export default MatchingComparisonPDF
