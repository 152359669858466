//React
import React from 'react'
import { useTranslation } from "react-i18next";
import { MailIcon, CalendarIcon } from '@heroicons/react/solid'
import HeaderLogo from '../design/HeaderLogo';

//Hooks

import MatchingComparisonPDF from '../layout/MatchingComparisonPDF'
import { IUserData } from '../../hooks/useUpdateProfile'
import { ICompetenceData } from '../../helpers/transform'


interface Props {
  //targetRef?: any,
  personalData:{
    name?:string,
    email?:string,
    department?:string,
    position?:string,
    profile?:string,
    date?:string,
    score?: string
  }, 
  competenceData: ICompetenceData | null,
  userData: IUserData,
  profileData: IUserData,
  employeeView?: boolean
}
export interface IState {
  //points?: number
  description?: string,
  name?: string,
  high?: string,
  low?: string,
  level?: any,
  element?: string
}

const MatchingComparisonViewPDF: React.FC<Props> = ({personalData={}, competenceData=null, userData={}, profileData={}, employeeView=false}) => {
  
  const { t } = useTranslation(['matchingComparisonPDF']);

  //let textActualProfile = t('comparison.profileActual')

  return competenceData ? (
    <>
        <div>
            <HeaderLogo/>
            <div className='pb-3 border-b border-gray-200 mb-3'></div>
            <div className="">
                <div className="flex items-center pb-2">
                    {personalData?.position ? (<div className="lg:text-3xl text-xl font-bold leading-tight text-uw-teal pb-2">{personalData?.position}
                    </div>
                    ) : (
                    <div className="lg:text-3xl text-xl font-bold leading-tight text-uw-teal pb-2">
                    {t("headline")}
                    </div>)}
                    <div className="flex items-center pb-2">
                        {personalData?.score ? <div className="absolute right-0 mr-6 mt-1 text-2xl text-uw-teal font-medium box-border divide-x divide-uw-teal rounded-b-lg px-4 py-4 bg-gray-50">{personalData?.score} %</div> : null}
                    </div>
                </div>
                <div className="">
                    <div className="flex space-x-1"> 
                        {personalData?.name && (
                        <div className="text-md text-uw-teal font-medium">{personalData?.name}</div>
                        )}
                        {personalData?.profile && (
                        <div className="text-md text-uw-teal font-medium">
                            |  {personalData?.profile}
                        </div>
                        )}
                        {personalData?.department && (
                        <div className="text-md text-uw-teal font-medium">
                            |  {personalData?.department}
                        </div>
                        )}
                    </div>
                    </div>
            </div>
            <div className="mt-1 flex items-center">
            {personalData?.email ? (
                <>
                <div className="mt-3.5">
                <MailIcon className="mr-2 h-4 w-4 text-gray-400"/>
                </div>
                <div className="text-xs text-gray-700 font-medium">{personalData?.email}   
                </div>
                </>
                ) : null}
            </div>
            <div className="-mt-2 mb-1 flex items-center">
            {personalData?.date ? (
                <>
                <div className="mt-3.5">
                    <CalendarIcon className="mr-2 h-4 w-4 text-gray-400" />
                </div>
                <div className="text-xs text-gray-700 font-medium">
                    {t("applicationDate")} {personalData?.date}
                </div>
                </>
            ) : null}
            </div>
             
            <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[25]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[2]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[35]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[5].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[19]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[6]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[38]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[29]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[7].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[27]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[20]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[3].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[9]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[3]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[37]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[31]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[26]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[21]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[17]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[13]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[15]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[4].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[4]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[36]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[5]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[30]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[23]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[34]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[8].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[10]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[33]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[28]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[24]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[14]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[12]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[6].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[7]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[39]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[8]} userData={userData} profileData={profileData} />
            <div className='pb-3 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 pt-4" style={{ breakInside: 'avoid' }}>
                <h3 className="text-xl leading-6 font-medium text-uw-teal mb-6">{competenceData.category[1].name}</h3>
                <MatchingComparisonPDF competenceData={competenceData.trait[1]} userData={userData} profileData={profileData} />
            </div>
                <MatchingComparisonPDF competenceData={competenceData.trait[32]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[22]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[18]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[16]} userData={userData} profileData={profileData} />
                <MatchingComparisonPDF competenceData={competenceData.trait[11]} userData={userData} profileData={profileData} />
            </div>
    </>
  ) : (
    <>Fehler PDF Generierung</>
  )
}

export default MatchingComparisonViewPDF


