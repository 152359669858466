import React from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { BellIcon, MenuIcon, XIcon, CogIcon } from '@heroicons/react/outline'
import HeaderLogo from '../../design/HeaderLogo';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { UserRoles } from "../../../firebase/UserIntefaces"

export enum MAIN_MENU_IDS {
  DASHBOARD_COMPANY,
  DASHBOARD_RECRUITING,
  REQUIREMENT_PROFILES,
  COMPANY_MATCHING,
  CONTACT
}
export interface INavigationMainItem {
  id?: MAIN_MENU_IDS,
  name: string,
  href: string,
  current: boolean,
  roles?: UserRoles[]
}

export interface INavigationMetaItem {
  name: string,
  href: string
}

export interface INavigationUserItem {
  name: string,
  email: string,
  imageUrl: string
}

export interface INavigation {
  mainNavItems: INavigationMainItem[],
  metaNavItems: INavigationMetaItem[],
  userItems: INavigationUserItem
}

const CustomMenu: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const { i18n, t } = useTranslation('menu')
  const [language, setLanguage] = useState<string>()

  const changeLanguage = () => {
    if (i18n.language.includes('en')) {
      setLanguage('de')
    }
    else if (i18n.language.includes('de')) {
      setLanguage('en')
    }
    else if (i18n.language.includes('fr')) {
      setLanguage('fr')
    }
  }

  useEffect(() => {
    if (language === "de") i18n.changeLanguage('de')
    if (language === "en") i18n.changeLanguage('en')
    if (language === "fr") i18n.changeLanguage('fr')
  }, [language, i18n])

  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-200 shadow-headShadow fixed z-20 w-full">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <HeaderLogo />
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {mainNavItems.map((item) => (
                    <Link key={item.name} to={item.href} className={classNames(item.current
                      ? 'border-uw-teal text-gray-800'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      , 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium')}
                      aria-current={item.current ? 'page' : undefined}>
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              {/* Momentan verborgen, später einblenden um Notifications anzuzeigen */}
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <button
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6 hidden" aria-hidden="true" />
                </button>

                {/* <div className="flex items-center"> //Anzeige der Sprache wie bisher im Header

                                <div className="cursor-pointer">
                                    <p className="text-uw-teal text-xs">
                                        deutsch&nbsp;
                                    </p>
                                </div>
                                <div>
                                    <p className="text-uw-teal text-xs">
                                        |
                                    </p>
                                </div>
                                <div className="cursor-pointer">
                                    <p className="text-uw-teal text-xs">
                                        &nbsp;english
                                    </p>
                                </div>
                            </div> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button
                          className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                          <CogIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition show={open} as={Fragment} enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items static
                          className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {metaNavItems.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a href={item.href} className={classNames(active ? '' : ''
                                  , 'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100')}>
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uw-teal">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {mainNavItems.map((item) => (
                <a key={item.name} href={item.href} className={classNames(item.current
                  ? 'bg-uw-lightTeal border-uw-teal text-uw-teal'
                  : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
                  , 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium')} aria-current={item.current ? 'page' :
                    undefined}>
                  {item.name}
                </a>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="hidden flex-shrink-0"> {/* Aktuell verborgen */}
                  <img className="h-10 w-10 rounded-full" src={userItems.imageUrl} alt="" />
                </div>
                <div className="hidden ml-3"> {/* Aktuell verborgen */}
                  <div className="text-base font-medium text-gray-800">{userItems.name}</div>
                  <div className="text-sm font-medium text-gray-500">{userItems.email}</div>
                </div>
                <button
                  className="hidden ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {/* Aktuell verborgen */}
                  <span className="sr-only">View notifications</span>
                  <BellIcon className=" h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1">
                <div key="language" onClick={changeLanguage}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100  cursor-pointer">
                  {t('meta.language')}
                </div>
                {metaNavItems.map((item) => (
                  <a key={item.name} href={item.href}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default CustomMenu

export function setMainMenuIdActive(mainNavItems: INavigationMainItem[], mainMenuId: MAIN_MENU_IDS | null = null ) {
  mainNavItems.forEach((element) => {
    if(element.id === mainMenuId) {
      element.current = true
    } else {
      element.current = false
    }
  })
}